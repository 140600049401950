// SelectionControl.tsx

import { Aggregations, CategoryType, ChartConfig, ColumnType } from "@doowii-types/chart";
import { css } from "@styled-system/css";
import React from "react";

interface SelectionControlProps {
  id: string;
  chartConfig: ChartConfig;
  updateConfigState: (config: ChartConfig, updateFirestore?: boolean) => void;
}

const SelectionControl = ({ id, chartConfig, updateConfigState }: SelectionControlProps) => {
  const selectedColumns = chartConfig.columns;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const columnId = e.target.value;

    const newColumns = isChecked
      ? [...selectedColumns, columnId]
      : selectedColumns.filter((col) => col !== columnId);

    const newColumnAggregations = { ...chartConfig.column_aggregations };
    if (isChecked && !newColumnAggregations[columnId]) {
      newColumnAggregations[columnId] = Aggregations.SUM;
    }
    const categoricalOrTemporalColumns = newColumns.filter(
      (col) =>
        chartConfig.column_types[col] === ColumnType.CATEGORICAL ||
        chartConfig.column_types[col] === ColumnType.TEMPORAL
    );
    let newColumnGrouping = chartConfig.column_grouping || {};
    if (categoricalOrTemporalColumns.length !== 2) {
      newColumnGrouping = {};
    } else {
      const [col1, col2] = categoricalOrTemporalColumns;
      newColumnGrouping = {
        [col1]: CategoryType.GROUP,
        [col2]: CategoryType.TYPE,
      };
    }
    const newChartConfig = {
      ...chartConfig,
      columns: newColumns,
      column_aggregations: newColumnAggregations,
      column_grouping: newColumnGrouping,
    };
    updateConfigState(newChartConfig, false);
  };

  return (
    <input
      checked={selectedColumns.includes(id)}
      className={css({
        cursor: "pointer",
      })}
      onChange={handleChange}
      type="checkbox"
      value={id}
    />
  );
};

export { SelectionControl };

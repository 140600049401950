import { Aggregations, CategoryType } from "@doowii-types/chart";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "doowii-ui";
import React from "react";

interface GroupingDropdownProps {
  categoricalColumnType: CategoryType;
  onGroupingChange: (type: CategoryType) => void;
}

const GroupingDropdown: React.FC<GroupingDropdownProps> = ({
  categoricalColumnType,
  onGroupingChange,
}) => {
  const { _ } = useLingui();
  const groupingOptions: { label: string; value: CategoryType }[] = [
    { label: _(msg`Group`), value: CategoryType.GROUP },
    { label: _(msg`Type`), value: CategoryType.TYPE },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button iconRight={faChevronDown} size="xsmall" variant="dropdown">
          {categoricalColumnType
            ? categoricalColumnType.charAt(0).toUpperCase() + categoricalColumnType.slice(1)
            : _(msg`Categorical Column Type`)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {groupingOptions.map((option) => (
          <DropdownMenuItem key={option.value} onClick={() => onGroupingChange(option.value)}>
            {option.label}
          </DropdownMenuItem>
        ))}{" "}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { GroupingDropdown };

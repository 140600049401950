import { VisualizationType } from "./viz";

export enum Aggregations {
  SUM = "sum",
  MEAN = "mean",
  MIN = "min",
  MAX = "max",
  COUNT = "count"
}

export enum CategoryType {
  GROUP = "group",
  TYPE = "type"
}

export enum ColumnType {
  CATEGORICAL = "categorical",
  NUMERICAL = "numerical",
  TEMPORAL = "temporal"
}

export interface ChartConfig {
  suggestion: VisualizationType;
  columns: string[];
  column_types: Record<string, ColumnType>;
  options: Record<string, any>;
  column_aggregations: Record<string, Aggregations>;
  column_grouping: Record<string, CategoryType>;
}



export interface ChartAPIResponse {
  status: string;
  chart_config: ChartConfig;
  explanation: string;
  suggestions: string | null;
}

export type DataRow = Record<string, any>;


import { ChartConfig, ColumnType } from "@doowii-types/chart";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";

/**
 * Determines the available chart types based on the selected columns and their data types.
 *
 * @param {ChartConfig["column_types"]} columnTypes - An object mapping column names to their data types.
 * @param {string[]} selectedColumns - An array of selected column names.
 * @returns {DataVizType[]} - An array of available DataVizTypes for the selected columns.
 */
export const getAvailableCharts = (
  columnTypes: ChartConfig["column_types"],
  selectedColumns: string[]
): DataVizType[] => {
  // Map the selected column names to their corresponding data types
  const selectedColumnTypes = selectedColumns.map((col) => columnTypes[col]);

  // Separate the columns based on their data types
  const numericalColumns = selectedColumnTypes.filter((type) => type === ColumnType.NUMERICAL);
  const categoricalColumns = selectedColumnTypes.filter((type) => type === ColumnType.CATEGORICAL);
  const temporalColumns = selectedColumnTypes.filter((type) => type === ColumnType.TEMPORAL);

  // Count the number of columns for each type
  const numericalCount = numericalColumns.length;
  const categoricalCount = categoricalColumns.length;
  const temporalCount = temporalColumns.length;

  // Total number of categorical or temporal columns
  const categoricalOrTemporalCount = categoricalCount + temporalCount;

  // Initialize the list of available charts with TABLE (always available)
  const availableCharts: DataVizType[] = [DataVizTypes.TABLE];

  /**
   * Chart Type: SCATTER_CHART
   *
   * Requirements:
   * - Exactly 2 numerical columns
   * - At most 1 categorical or temporal column (used for grouping)
   * - No extra columns selected
   *
   * The transformation function for scatter charts uses the first two numerical columns
   * as the x and y axes. An optional categorical/temporal column can be used for grouping.
   */
  if (
    numericalCount === 2 && // Exactly 2 numerical columns
    categoricalOrTemporalCount <= 1 && // Zero or one categorical/temporal column
    selectedColumns.length === numericalCount + categoricalOrTemporalCount // No extra columns
  ) {
    availableCharts.push(DataVizTypes.SCATTER_CHART);
  }

  /**
   * Chart Type: BAR_CHART
   *
   * Requirements:
   * - At least 1 numerical column
   * - At least 1 categorical or temporal column
   * - Up to 2 categorical or temporal columns
   * - No extra columns selected
   *
   * The bar chart can handle multiple numerical and categorical/temporal columns.
   * All selected numerical and categorical/temporal columns are used in the chart.
   */
  if (
    numericalCount >= 1 && // At least 1 numerical column
    categoricalOrTemporalCount >= 1 && // At least 1 categorical/temporal column
    categoricalOrTemporalCount <= 2 && // Up to 2 categorical/temporal columns
    selectedColumns.length === numericalCount + categoricalOrTemporalCount // No extra columns
  ) {
    availableCharts.push(DataVizTypes.BAR_CHART);
  }

  /**
   * Chart Type: PIE_CHART
   *
   * Requirements:
   * - Exactly 1 numerical column
   * - Exactly 1 categorical column (not temporal)
   * - No extra columns selected
   *
   * The pie chart uses the first numerical column for the values and the first
   * categorical column for the categories. Extra columns are not utilized.
   */
  if (
    numericalCount === 1 && // Exactly 1 numerical column
    categoricalCount === 1 && // Exactly 1 categorical column
    selectedColumns.length === 2 // No extra columns
  ) {
    availableCharts.push(DataVizTypes.PIE_CHART);
  }

  /**
   * Chart Type: LINE_CHART
   *
   * Requirements:
   * - At least 1 numerical column
   * - At least 1 categorical or temporal column (used for the x-axis)
   * - Up to 2 categorical or temporal columns
   * - No extra columns selected
   *
   * The line chart uses the categorical/temporal columns for the x-axis and grouping,
   * and the numerical columns for the y-axis values.
   */
  if (
    numericalCount >= 1 && // At least 1 numerical column
    categoricalOrTemporalCount >= 1 && // At least 1 categorical/temporal column
    categoricalOrTemporalCount <= 2 && // Up to 2 categorical/temporal columns
    selectedColumns.length === numericalCount + categoricalOrTemporalCount // No extra columns
  ) {
    availableCharts.push(DataVizTypes.LINE_CHART);
  }

  return availableCharts;
};
